import { galaxyApp } from '../app'

galaxyApp.service('Configuration', ['$location', function($location) {
  this.AppCompany = setCompany()

  this.NywtEnvironmentKey = "1ZQQ5PAa0xTIspbd4ByrNdUxSy";
  this.CircleLineEnvironmentKey = "JqF8tMGdpOpFCKPuoJ2bTWdVOY7";
  this.ResaleNywtEnvironmentKey = "E79gtPGw4QKcprxPTzrcozYx8ow";
  this.ResaleCircleLineEnvironmentKey = "3lEWRGwr6vRbuVsYvjJzHy3yG5n";

  // CityPass vars
  this.CityPassBaseUrl = 'citypass.circleline.com';
  this.CityPassAPI = '/api/v1/city_pass';
  this.CityPassGroupPhone = '212-563-3200';

  this.Routes = {
    orders: {
      hold: '/orders/hold',
      authorize: '/orders/authorize',
      submit: '/orders/submit',
    },
    cityPass: {
      validate: '/passes/validate',
    },
    carts: {
      resource: '/carts',
    },
  }

  if (this.AppCompany == 'circle_line') {
    this.BaseURL = 'https://citypass.circleline.com';
    this.ContactEmail = 'infoCL@nycl.com';
    this.SupportPhone = '212-563-3200';
    this.GroupPhone = '212-630-8885';
    this.MaxTickets = '14';
  } else if (this.AppCompany == 'nycl_resale') {
    var appProtocol = $location.protocol();
    var appHost = $location.host();

    this.BaseURL = setResaleBaseUrl(appProtocol, appHost);
    this.ContactEmail = 'GKelly@nycl.com';
    this.SupportPhone = '212-630-8878';
    this.GroupPhone = '212-630-8885';
    this.Resale = true;
    this.MaxTickets = '50';
  } else {
    this.BaseURL = 'https://nywt-egalaxy-preprod.herokuapp.com';
    this.ContactEmail = 'nywtcallcenter@nycl.com';
    this.SupportPhone = '212-742-1969';
    this.GroupPhone = '212-742-1969';
    this.MaxTickets = '14';
  }

  this.API = `/companies/${this.AppCompany}/api/v1`;
  this.StoreUrl = `${this.BaseURL}/${this.AppCompany}`;
  this.AppApi = `${this.BaseURL}/api/v1`;
  this.DefaultErrorMessagge = 'Unable to process your order at this time. Please try again later or contact support if the problem persists.';

  function setCompany() {
    if (pathIncludes('nywt')) {
      return 'nywt';
    } else if (pathIncludes('circle_line')) {
      return 'circle_line';
    } else if (pathIncludes('city_pass') || pathIncludes('citypass')) {
      return 'circle_line';
    } else if (pathIncludes('nycl_resale')) {
      return 'nycl_resale';
    }
  }

  function pathIncludes(val) {
    return $location.path().toLowerCase().indexOf(val) !== -1
  }

  function setResaleBaseUrl(appProtocol, hostName) {
    var appUrl = appProtocol + '://' + hostName;
    if ('production' == 'development') {
      appUrl = 'https://nyclreseller.cn';
    }
    return appUrl
  }
}]);
